.buttonGroup{
  @apply
  flex-1 
  p-4 
  text-center 
  border 
  font-semibold
  text-lg
  cursor-pointer
  border-neutral-500
  rounded-2xl;
}

.selectedButton{
  @apply
  flex-1 
  p-4 
  text-center 
  font-semibold
  text-lg
  cursor-pointer
  text-neutral-950
  bg-amber-200
  rounded-2xl;
}