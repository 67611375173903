.container{
  @apply
  fixed
  flex 
  flex-col 
  items-stretch 
  justify-start 
  min-h-screen 
  gap-4 
  px-3 
  py-4 
  text-white 
  w-60 
  bg-neutral-950 
  animate-out 
  transition-all;
}

.container.open {
  @apply
  w-[64px]
  animate-in
  transition-all;
}

.headerContainer{
  @apply
  flex 
  flex-row 
  items-center 
  self-stretch 
  justify-start;
}

.headerTitle{
  @apply
  flex 
  flex-row 
  items-center 
  flex-1 
  gap-2
  animate-out
  transition-all;
}

.headerTitle.open {
  @apply
  opacity-0
  w-0
  animate-in
  transition-all;
  span{
    @apply
    hidden;
  }
}

.containerLogo{
  @apply
  bg-blue-600 
  flex items-center 
  justify-center 
  w-[40px]
  h-[40px]
  rounded-[8px]
  transition-all;
}

.headerToggle{
  @apply
  flex 
  items-center 
  justify-center
  w-[40px] 
  rounded-lg 
  h-[40px] 
  z-10 
  cursor-pointer
  hover:bg-neutral-900;
}


.menuItem{
  @apply
  flex 
  flex-row 
  items-center 
  self-stretch 
  h-[40px] 
  hover:bg-neutral-800 
  rounded-lg
  py-4
  cursor-pointer
  transition-all;
}

.menuItemIcon{
  @apply
  flex 
  flex-row 
  justify-center 
  items-center 
  min-w-[40px] 
  h-[40px];
}

.menuItemTitle{
  @apply
  text-[14px]
  block;
}

.menuItemTitle.open{
  @apply
  text-[14px]
  hidden;
}


.avatar{
  @apply
  flex 
  items-center 
  justify-center 
  w-8 
  h-8 
  text-sm 
  font-bold 
  rounded-full 
  cursor-pointer 
  min-w-8 
  bg-amber-300 
  text-neutral-950;
}
