.tabsTrigger{
  @apply
  flex 
  flex-col 
  items-center 
  self-stretch 
  justify-center 
  flex-1 
  gap-1 
  py-2 
  rounded-2xl;
}

.customTabsTrigger{
  @apply
  flex 
  flex-row 
  items-center 
  self-stretch 
  justify-center 
  flex-1 
  gap-2
  h-[50px]
  rounded-2xl
  data-[state=active]:bg-amber-200 
  data-[state=active]:border-amber-200;
}

.buttonGroup{
  @apply
  flex-1 
  p-4 
  text-center 
  border 
  font-semibold
  text-lg
  cursor-pointer
  border-neutral-500
  rounded-2xl;
}

.selectedButton{
  @apply
  flex-1 
  p-4 
  text-center 
  font-semibold
  text-lg
  cursor-pointer
  text-neutral-950
  bg-amber-200
  rounded-2xl;
}

.handpayAlert{
  @apply
  absolute 
  top-[-8px]
  right-[-8px]
  bg-red-600
  animate-bounce
  text-white
  rounded-full
  w-7
  h-7
  flex
  items-center
  justify-center
  text-sm
  font-semibold;
}

.cardPayContainer{
  @apply
  flex 
  flex-col 
  flex-1 
  gap-2 
  p-4 
  bg-center
  bg-no-repeat 
  bg-contain 
  cursor-pointer 
  rounded-xl 
  bg-neutral-950 
  hover:bg-amber-200
  hover:text-neutral-950
  hover:font-semibold
  min-h-[167px]
  transition-all
  duration-100;
}

.cardBankTitle{
  @apply
  text-sm
  font-semibold;
}

.cardTypeTitle{
  @apply
  font-semibold 
  leading-6;
}

.cardNumber{
  @apply
  text-center;
  font-family:'Courier New', Courier, monospace;
}

@media (max-width: 1300px) {
  .cardBankTitle {
    @apply 
    text-xs;
  }
}

@media (max-width: 1360px) {
  .cardTypeTitle{
    @apply
    text-xs;
  }
}

@media (max-width: 1210px) {
  .cardNumber{
    @apply
    text-[13px];
  }
}

.cardSelected {
  @apply 
 bg-amber-200
 text-neutral-950
  transition-all
  duration-100
  font-semibold;
}

.containerIcon{
  @apply
  flex 
  items-center 
  justify-center 
  w-10 
  h-10 
  bg-neutral-900 
  rounded-lg 
  text-white
  hover:bg-amber-600;
}