
.selectorContainer{
  @apply
  max-w-[1200px] 
  w-full 
  grid 
  items-center 
  justify-center 
  gap-4 
  grid-cols-auto-fill-200 
  justify-items-stretch;
}

.selectorItem{
  @apply
  flex 
  flex-col 
  items-center 
  self-stretch 
  justify-center 
  gap-4
  p-4 
  bg-white 
  shadow-sm 
  cursor-pointer 
  rounded-2xl 
  grayscale 
  hover:grayscale-0;
}

.selectorItemImg{
  @apply
  flex 
  items-center 
  justify-center 
  max-w-[120px] 
  min-h-[120px];
}

.selectorItemText{
  @apply
  font-bold 
  text-center 
  uppercase 
  transition-all 
  ease-in-out;
}