@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply text-neutral-950 dark:text-white;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.history {
  @apply
  decoration-neutral-950/50
  underline
  decoration-1
  underline-offset-4
  cursor-pointer;
}

.active{
  @apply
  bg-neutral-800;
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  @apply
  bg-neutral-500
  rounded-md;
}

::-webkit-scrollbar-thumb {
  @apply
  bg-neutral-800
  rounded-md
  border-2 
  border-solid
  border-neutral-500;
}

@keyframes slideArrow {
  0% {
    opacity: 0;
    transform: translateX(-50%);
  }
  50% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(50%);
  }
}

.slide-arrow {
  animation: slideArrow 2s linear infinite;
}
