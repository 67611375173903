.container{
  @apply
  flex 
  flex-col 
  self-stretch
  max-[1024px]:w-[240px]
  w-[280px] 
  gap-4
  p-4
  max-[1024px]:px-2
  min-h-[400px]
  bg-neutral-800 
  rounded-2xl;
}

.containerItem{
  @apply
  flex 
  flex-col 
  gap-3 
  p-4
  max-[1024px]:px-2
  border 
  cursor-pointer 
  border-neutral-500 
  rounded-2xl 
  hover:bg-white 
  hover:text-neutral-950;
}

.containerIcon{
  @apply
  flex 
  items-center 
  justify-center 
  w-10 
  h-10 
  bg-neutral-700 
  rounded-lg 
  text-white;
}

.selectedMachine{
  @apply
  bg-white
  text-neutral-950;
}