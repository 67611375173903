.tabsTrigger{
  @apply
  flex 
  flex-col 
  items-center 
  self-stretch 
  justify-center 
  flex-1 
  gap-1 
  py-4 
  rounded-2xl;
}

.customTabsTrigger{
  @apply
  flex 
  flex-row 
  items-center 
  self-stretch 
  justify-center 
  flex-1 
  gap-2
  h-[50px]
  rounded-2xl
  data-[state=active]:bg-amber-200 
  data-[state=active]:border-amber-200;
}