.container{
  @apply
  flex 
  flex-row 
  min-h-screen 
  select-none;
}

.containerMain{
  @apply
  flex 
  self-stretch 
  justify-center 
  flex-1 
  p-4 
  text-white 
  bg-neutral-900 
  ml-60
  animate-in
  transition-all;

}

.containerMain.open {
  @apply
  ml-16
  animate-in
  transition-all;
}